<template>
  <span>
    <p
      class="text-left text-secondary text-caption ml-2 pb-0 pt-0 mb-0 mt-0"
      v-if="label"
    >
      {{ label }}
    </p>

    <v-autocomplete v-bind="{ ...$attrs, ...commonAttrs }" v-on="$listeners">
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        #[scopedSlotName]="slotData"
      >
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
      <template v-for="(_, slotName) in $slots" #[slotName]>
        <slot :name="slotName" />
      </template>
    </v-autocomplete>
    <p
      class="text-left text-primary text-caption ml-2 mb-2 mt-0"
      v-if="errorMessages[0]"
    >
      {{ errorMessages[0] ? errorMessages[0] : "" }}
    </p>
  </span>
</template>

<script>
export default {
  inheritAttrs: false,

  computed: {
    commonAttrs() {
      return {
        class:
          "input-style font-size-input text-light-input border placeholder-light border-radius-md select-style mt-0 mb-2 dimmed-placeholder",
        outlined: true,
        singleLine: true,
        hideDetails: true,
        backgroundColor: this.getBackgroundColor,
      };
    },

    getBackgroundColor() {
      if (this.$attrs.disabled) return "rgba(240,240,240,.9)";
      return "rgba(255,255,255,.9)";
    },
  },

  props: {
    label: {
      type: String,
      default: "",
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style scoped>
.dimmed-placeholder ::placeholder {
  color: rgba(0, 0, 0, 0.38) !important;
  opacity: 0.6;
}

/* For Firefox */
.dimmed-placeholder ::-moz-placeholder {
  color: rgba(0, 0, 0, 0.38) !important;
  opacity: 0.6;
}

/* For Internet Explorer */
.dimmed-placeholder :-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.38) !important;
  opacity: 0.6;
}

/* For Microsoft Edge */
.dimmed-placeholder ::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.38) !important;
  opacity: 0.6;
}
</style>
